// import bugsnag from 'bugsnag-js';
import Bugsnag from '@bugsnag/js';
import { LoggedInUser } from 'Dart';

const BUGSNAG_KEY = '02383d421be21b6a8e6054da65444dbc';

export const SetupBugsnag = () => {
    Bugsnag.start({
        apiKey: BUGSNAG_KEY,
        enabledReleaseStages: [ 'production', 'staging' ],
        releaseStage: build.environment || process.env.NODE_ENV || 'development'
    });

    const user = LoggedInUser();
    if (user !== false) {
        Bugsnag.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`);
        Bugsnag.addMetadata('user', 'unit', user.unit)
        Bugsnag.addMetadata('user', 'phone', user.phone)
        Bugsnag.addMetadata('user', 'type', user.type)
        Bugsnag.addMetadata('user', 'numericType', user.numericType)
    }
}; // End set