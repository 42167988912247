/**
 * @author: Håvard Melvin Asperheim Hjelvik
 * @date: 05/10/2017
 */

import { SetupBugsnag } from '../../common';

import {parseUrl} from 'Core';
import Dart from 'Dart';

class Base {
    static init = async () => {
        SetupBugsnag();

        try {

            const [SystemUsersPasswordChange, Login] = await Promise.all([
                import('../../../Modules/System/ViewTempl/js/SystemUserPasswordChange'),
                import('../../../Modules/MelvisAdmin/ViewTempl/js/Login'),
            ]);

            SystemUsersPasswordChange.default.init();
            Login.default.init();

        } catch(error) {
            console.error(error);
        }

    } // End init
} // End Base

Dart.ready(Base.init);